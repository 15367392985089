import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Glider, GliderStatus, UpdateStatusPayload } from '@app/core/interfaces/glider.interace';
import { Response } from '@app/core/interfaces/responses/response.interface';
import { typeAction } from '@app/shared/utils/enum';
import { environment } from '@src/environments/environment';
import { Observable, Subject, from } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class FleetService {
  private apiUrl = environment.urlMsFleet;
  private subjectFleets = new Subject<Glider>();
  public action: typeAction | undefined;

  constructor(private http: HttpClient, private keycloakService: KeycloakService) {}

  getFleetList(): Observable<Response<Glider[]>> {
    return from(this.keycloakService.getToken()).pipe(
      switchMap((token) => {
        const headers = new HttpHeaders({
          Authorization: `Bearer ${token}`,
        });
        return this.http.get<Response<Glider[]>>(`${this.apiUrl}/gliders`, { headers });
      })
    );
  }

  getFleetStatusList(): Observable<Response<GliderStatus[]>> {
    return from(this.keycloakService.getToken()).pipe(
      switchMap((token) => {
        const headers = new HttpHeaders({
          Authorization: `Bearer ${token}`,
        });
        return this.http.get<Response<GliderStatus[]>>(`${this.apiUrl}/glider-modes`, { headers });
      })
    );
  }

  getFleetById(fleetId: number): Observable<Response<Glider>> {
    return from(this.keycloakService.getToken()).pipe(
      switchMap((token) => {
        const headers = new HttpHeaders({
          Authorization: `Bearer ${token}`,
        });
        return this.http.get<Response<Glider>>(`${this.apiUrl}/gliders/${fleetId}`, { headers });
      })
    );
  }

  notifyDataSubject(fleet?: Glider, action?: typeAction): void {
    this.action = action;
    this.subjectFleets.next(fleet);
  }

  listenDataSubjectFleet(): Observable<Glider> {
    return this.subjectFleets.asObservable();
  }

  updateDroneStatus(payload: UpdateStatusPayload & { note: string, userEmail: string }): Observable<any> {
    const url = `${this.apiUrl}/gliders/${encodeURIComponent(payload.gliderName)}/update-status/${payload.status}?note=${encodeURIComponent(payload.note)}&userEmail=${encodeURIComponent(payload.userEmail)}`;
    return this.http.patch(url, {});
  }

  updateGlider(gliderId: number, payload: any): Observable<Glider> {
    return this.http.patch<Glider>(`${this.apiUrl}/gliders/${gliderId}`, payload);
  }

}
